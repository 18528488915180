import { useCallback, useEffect, useMemo, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useAccount, type Connector } from "wagmi"
import makeBlockie from "ethereum-blockies-base64"
import { useRouter } from "next/router"
import { destroyCookie } from "nookies"

import { useMe } from "user/providers/MeProvider"
import { getDisplayName } from "user/helpers/user"
import { useSignerStore } from "web3/providers/SignerProvider"

export enum Chain {
  EVM = "evm",
  SOL = "sol",
}

type MultichainAccount = {
  address: string
  chain: Chain
  picture?: string | null
  type: string
  id: string
  displayName: string
}

type UseMultichainAccount = {
  disconnect: (redirectTo?: string) => void
  multichainAccount: MultichainAccount
  isInitialized: boolean
}

type EVMAccountCallbackArgs = {
  address?: `0x${string}`
  connector?: Connector<any, any, any>
  isReconnected: boolean
}

type EVMAccountConfig = {
  onConnect?: (args: EVMAccountCallbackArgs) => void
  onDisconnect?: () => void
}

const initialMultichainAccount = {
  address: "",
  chain: Chain.EVM,
  picture: "",
  type: "",
  id: "",
  displayName: "",
} satisfies MultichainAccount

export function useMultichainAccount(
  evmAccountConfig?: EVMAccountConfig,
): UseMultichainAccount {
  const me = useMe()
  const { address: walletAddress, connector } = useAccount(evmAccountConfig)
  const { signer } = useSignerStore()
  const {
    disconnect: sDisconnect,
    publicKey,
    connected: sConnected,
  } = useWallet()
  const { reload, push } = useRouter()
  const [addressToUse, setAddressToUse] = useState("")
  const [isInitialized, setIsInitialized] = useState(false)

  const activeChain = useMemo(
    () => (publicKey ? Chain.SOL : Chain.EVM),
    [publicKey],
  )

  const disconnectMap: Record<string, any> = useMemo(
    () => ({
      [Chain.EVM]: async () => connector?.disconnect(),
      [Chain.SOL]: async () => sDisconnect(),
    }),
    [connector, sDisconnect],
  )

  const disconnect = useCallback(
    async (redirectTo?: string) => {
      if (
        !activeChain ||
        (activeChain === Chain.EVM && !connector?.disconnect)
      ) {
        return
      }

      destroyCookie(null, "token", { path: "/" })

      await disconnectMap[activeChain]()

      setTimeout(() => {
        if (redirectTo) {
          push(redirectTo).then(reload)
        } else {
          reload()
        }
      }, 1000)
    },
    [activeChain, disconnectMap, connector?.disconnect, push, reload],
  )

  const multichainAccount = useMemo(() => {
    if (me) {
      const { address, picture, type, id } = me
      const displayName = getDisplayName(me)

      return {
        displayName,
        address:
          me.type === "SAFE"
            ? walletAddress ?? (addressToUse as string)
            : address,
        picture,
        type,
        id,
        chain: activeChain,
      }
    }

    if (!me && (walletAddress || addressToUse || (sConnected && publicKey))) {
      return {
        address: publicKey?.toBase58() ?? walletAddress ?? addressToUse ?? "",
        picture: makeBlockie(
          publicKey?.toBase58() || walletAddress || addressToUse || "",
        ),
        type: "",
        id: "",
        displayName:
          publicKey?.toBase58() ?? walletAddress ?? addressToUse ?? "",
        chain: activeChain,
      }
    }

    return initialMultichainAccount
  }, [walletAddress, addressToUse, me, sConnected, publicKey, activeChain])

  useEffect(() => {
    if (addressToUse || walletAddress || signer || activeChain === Chain.SOL) {
      if (!isInitialized) {
        setIsInitialized(true)
      }

      return
    }

    if (isInitialized) {
      return
    }

    async function getSignerAddress() {
      if (!signer) {
        return
      }

      setIsInitialized(false)

      const signerAddress = await signer?.getAddress()
      setAddressToUse(signerAddress as string)
      setIsInitialized(true)
    }

    getSignerAddress()
  }, [signer, walletAddress, addressToUse, isInitialized, activeChain])

  return {
    disconnect,
    multichainAccount,
    isInitialized,
  }
}
