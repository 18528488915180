export const shortString = (
  value: string,
  initialLength = 6,
  endLength = -4,
): string => `${value.slice(0, initialLength)}...${value.slice(endLength)}`

export const subString = (
  value: string,
  endLength = 16,
  appendText = "...",
): string => {
  if (value === undefined) return ""

  return value.length > endLength
    ? value.substring(0, endLength) + appendText
    : value
}

export function firstLetterToUpper(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
}

export const getValidJsonString = (input: string) => {
  return input.replace(/'/g, '"')
}

export const pluralize = (
  number: number,
  singularText: string,
  pluralText: string,
): string => {
  return number === 1 ? singularText : pluralText
}

// Convert a signature like
//   addAsset(address,uint256,(address,address))
// to just this
//   addAsset(..)
export const simplifySignature = (signature: string) => {
  return signature.replace(/\(.*\)/, "(..)")
}

export function shortSolAddress(str: string) {
  return str.substring(0, 4) + "..." + str.substring(str.length - 4)
}

/**
 *
 * @param {String} string
 * @returns {String}
 * @description convert string to lowercase, remove spaces, remove special chars, remove accents
 * @example
 * normalize(' 123 ABC --/?+=  SÉ[ET') // will return "123abcseet"
 */
export function normalize(str?: string) {
  return str && typeof str === "string"
    ? str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9]/g, "")
    : ""
}
