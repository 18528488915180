import { PublicKey } from "@solana/web3.js"
import { utils } from "ethers"
import { isAddress } from "@ethersproject/address"
import isEqual from "lodash.isequal"

import { getMainnetReference } from "web3/helpers/chainReference"
import { addressToAccountId } from "web3/helpers/transformers"
import { shortSolAddress, normalize } from "common/helpers/string"

export const isSameAddress = (
  aAddress: string | undefined,
  bAddress: string | undefined,
): boolean => {
  return isEqual(normalize(aAddress), normalize(bAddress))
}

// Should put this one in a common helper when needed
function shortenString(str: string) {
  return str.substring(0, 6) + "..." + str.substring(str.length - 4)
}

function shortenAddress(address: string): string {
  try {
    const formattedAddress = utils.getAddress(address)

    return shortenString(formattedAddress)
  } catch {
    return ""
  }
}

// shorten an address if it's valid
export function shortenIfAddress(address: string | undefined): string {
  if (typeof address === "string" && address.length > 0) {
    if (isAddress(address)) {
      return shortenAddress(address)
    }

    if (isSolanaAddress(address)) {
      return shortSolAddress(address)
    }
  }

  return ""
}

export function isSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address)

    return true
  } catch {
    return false
  }
}

/**
 * From an address returns the account id
 * If EVM address, then returns eip155:1:{evmAddress}
 * If Solana address and chainId is sent, then returns {chainId}:{solanaAddress}
 * Otherwise, returns undefined
 */
export function getAddressToAccountId(
  address: string,
  chainId?: string,
): string | undefined {
  if (!address) return undefined
  if (!isSolanaAddress(address)) {
    return addressToAccountId(
      address ? (address as `0x${string}`) : "",
      getMainnetReference(),
    )
  }

  if (chainId?.includes("solana")) {
    return `${chainId}:${address}`
  }

  return undefined
}
