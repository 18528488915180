import type { ReactNode, Dispatch, SetStateAction } from "react"
import {
  useState,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react"
import { useAccount, useConnect, useSigner } from "wagmi"
import type { Signer, FetchSignerResult } from "@wagmi/core"
import { providers } from "ethers"
import { useRouter } from "next/router"

import type { DetectedConnector } from "web3/DetectedConnector"
import { useMe } from "user/providers/MeProvider"

type SignerContextType = {
  signer?: FetchSignerResult<Signer>
  status: "error" | "idle" | "loading" | "success"
  setChainIdForSigner: (chainId: number) => void
  setEthersSigner: Dispatch<SetStateAction<providers.JsonRpcSigner | null>>
}

const SignerContext = createContext<SignerContextType | null>(null)

export function SignerProvider({ children }: { children: ReactNode }) {
  const [chainId, setChainId] = useState<number | undefined>(undefined)
  const { data: signer, status } = useSigner({ chainId })
  const [ethersSigner, setEthersSigner] =
    useState<providers.JsonRpcSigner | null>(null)
  const { connectors } = useConnect()
  const me = useMe()
  const { address } = useAccount()
  const { reload } = useRouter()

  const initializeEthersSigner = useCallback(async (provider: any) => {
    try {
      const ethersProvider = new providers.Web3Provider(provider)

      // check if we're already connected
      const accounts = await ethersProvider.listAccounts()

      if (accounts.length === 0) {
        await ethersProvider.send("eth_requestAccounts", [])
      }

      const signer = ethersProvider.getSigner()

      setEthersSigner(signer)
    } catch (error) {
      console.error("Failed to initialize ethers signer:", error)
      setEthersSigner(null)
    }
  }, [])

  // safe has issues maintaining a signer on page refreshes, so we can
  // set up a signer with ethers in the event this happens
  useEffect(() => {
    if (signer) {
      return
    }

    async function getSigner() {
      const connectedConnector = connectors.find(
        (connector) => (connector as DetectedConnector).isConnected,
      )

      if (!connectedConnector) {
        return
      }

      try {
        const provider = await connectedConnector.getProvider()

        if (provider) {
          await initializeEthersSigner(provider)
        }
      } catch (error) {
        console.error("Error getting provider:", error)
      }
    }

    getSigner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors.length, signer, initializeEthersSigner, me?.type])

  useEffect(() => {
    if (!ethersSigner) return

    const provider = ethersSigner.provider as providers.Web3Provider

    const handleAccountsChanged = async () => {
      await initializeEthersSigner(provider)
    }

    const handleChainChanged = () => {
      initializeEthersSigner(provider)
    }

    provider.on("accountsChanged", handleAccountsChanged)
    provider.on("chainChanged", handleChainChanged)

    return () => {
      provider.removeListener("accountsChanged", handleAccountsChanged)
      provider.removeListener("chainChanged", handleChainChanged)
    }
  }, [ethersSigner, initializeEthersSigner])

  useEffect(() => {
    // TODO(dan): improve this with an upgrade to newer wagmi version
    // wagmi's signer doesn't update with the new address when you connect with
    // another wallet when already connected with a wallet. in the even this
    // happens we can reload the page, similar to how we handle `disconnect` to
    // refresh the signer.
    async function reloadIfDifferent() {
      const signerAddress = await signer?.getAddress()

      if (address && signerAddress && signerAddress !== address) {
        reload()
      }
    }

    reloadIfDifferent()
  }, [reload, address, signer])

  const setChainIdForSigner = useCallback((nextChainId: number) => {
    setChainId(nextChainId)
  }, [])

  const value = useMemo(
    () => ({
      signer: signer || ethersSigner,
      status,
      setChainIdForSigner,
      setEthersSigner,
    }),
    [signer, status, setChainIdForSigner, setEthersSigner, ethersSigner],
  )

  return (
    <SignerContext.Provider value={value}>{children}</SignerContext.Provider>
  )
}

export function useSignerStore(): SignerContextType {
  const context = useContext(SignerContext)

  if (!context) {
    throw new Error("useSignerStore must be used within a SignerProvider")
  }

  return context
}
