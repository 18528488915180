/* eslint-disable no-restricted-imports */
import ChakraButton from "./components/button"
import { colors } from "./foundations/colors"

const ZKSYNC_THEME = {
  colors: {
    ...colors,
    purple: {
      ...colors.purple,
      100: "#dae5fc",
      600: "#0d52df",
    },
  },
  components: {
    Button: {
      variants: {
        ...ChakraButton.variants,
        primary: {
          color: "white",
          bg: "#1755F4",
          border: "#1755F4",
          _disabled: {
            opacity: 0.7,
            bg: "#1755F4",
          },
          _hover: {
            bg: "#1755F4",
            opacity: 0.9,
            _disabled: {
              bg: "#1755F4",
              opacity: 0.7,
            },
          },
        },
      },
    },
  },
}

const WORMHOLE_THEME = {
  components: {
    Button: {
      variants: {
        ...ChakraButton.variants,
        brand: {
          color: "black",
          bg: "#C0BBF2",
          border: "#C0BBF2",
          _disabled: {
            opacity: 0.7,
            bg: "#C0BBF2",
          },
          _hover: {
            bg: "#C0BBF2",
            opacity: 0.9,
            _disabled: {
              bg: "#C0BBF2",
              opacity: 0.7,
            },
          },
        },
      },
    },
  },
}

const OBOL_THEME = {
  colors: {
    ...colors,
    green: {
      ...colors.green,
      100: "#2FE4AB",
    },
  },
  components: {
    Button: {
      variants: {
        ...ChakraButton.variants,
        primary: {
          color: "black",
          bg: "#2FE4AB",
          border: "#2FE4AB",
          _disabled: {
            opacity: 0.7,
            bg: "#2FE4AB",
          },
          _hover: {
            bg: "#2FE4AB",
            opacity: 0.9,
            _disabled: {
              bg: "#2FE4AB",
              opacity: 0.7,
            },
          },
        },
      },
    },
  },
}

// Hardcoded list of organizations with custom theme
const ORG_CUSTOM_THEME: Record<string, Record<string, any>> = {
  "https://api.tally.xyz": {
    codename: ZKSYNC_THEME,
    zksync: ZKSYNC_THEME,
    "zksync-private": ZKSYNC_THEME,
    wormhole: WORMHOLE_THEME,
    "wormhole-private": WORMHOLE_THEME,
    "codename-testnet": ZKSYNC_THEME,
    "testnet-zksync": ZKSYNC_THEME,
    "testnet-zksync-staging": ZKSYNC_THEME,
    obol: OBOL_THEME,
  },

  "https://api.staging.tally.xyz": {
    // Scale ETH codebase: https://staging.tally.xyz/gov/scale-eth-codename
    wormhole: WORMHOLE_THEME,
    "wormhole-private": WORMHOLE_THEME,
    "mainnet-wormhole": WORMHOLE_THEME,
    "testnet-wormhole": WORMHOLE_THEME,
    "obol-testnet": OBOL_THEME,
    "scale-eth-codename": {
      components: {
        Button: {
          variants: {
            ...ChakraButton.variants,
            primary: {
              color: "white",
              bg: "rgba(13, 82, 223, 1)",
              border: "blue.600",
              _disabled: {
                opacity: 0.7,
                bg: "rgba(13, 82, 223, 1)",
              },
              _hover: {
                bg: "rgba(13, 82, 223, 1)",
                opacity: 0.9,
                _disabled: {
                  bg: "rgba(13, 82, 223, 1)",
                  opacity: 0.7,
                },
              },
            },
          },
        },
      },
    },
  },
}

export const getOrganizationCustomTheme = (
  organizationSlug: string,
): any | undefined => {
  return ORG_CUSTOM_THEME?.[process.env.NEXT_PUBLIC_TALLY_API_URL]?.[
    organizationSlug
  ]
}
