import { useCallback, useState } from "react"
import {
  Program,
  AnchorProvider,
  type Idl,
  type IdlAccounts,
  type Wallet,
} from "@coral-xyz/anchor"
import {
  PublicKey,
  type TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js"
import { useWallet, useConnection } from "@solana/wallet-adapter-react"
import BN from "bn.js"
import {
  createTransferInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token"

import wormholeStakingIDL from "web3/solana/wormhole-staking/staking.json"
import { type Staking } from "web3/solana/wormhole-staking/staking"
import { WHTokenBalance } from "web3/solana/wormhole-staking/WHTokenBalance"
import { useTokenBalance } from "web3/solana/hooks/useTokenBalance"
import { useModals } from "common/hooks/useModals"

type StakeAccountMetadata = IdlAccounts<Staking>["stakeAccountMetadata"]

const IDL = wormholeStakingIDL as Idl

export const WORMHOLE_TOKEN_DECIMALS = 6

export const WORMHOLE_MINT = new PublicKey(
  process.env.NEXT_PUBLIC_WORMHOLE_MINT as string,
)

enum ConfigSeeds {
  CHECKPOINT_DATA = "owner",
  STAKE_METADATA = "stake_metadata",
  PROPOSAL = "proposal",
  VESTING_BALANCE = "vesting_balance",
  CUSTODY = "custody",
}

export enum TransactionStatus {
  NONE = "none",
  DELEGATE = "delegate",
  CREATE_ACCOUNT = "create_account",
  CAST_VOTE = "cast_vote",
  TRANSFER_TO_STAKE_ACCOUNT = "transfer_to_stake_account",
  CREATE_AND_TRANSFER = "create_and_transfer",
  WITHDRAW = "withdraw",
  FAIL = "fail",
  SUCCESS = "success",
}

export function useMultigovStaking() {
  const {
    publicKey,
    sendTransaction,
    signTransaction,
    signAllTransactions,
    wallet,
  } = useWallet()
  const { connection } = useConnection()
  const [isLoading, setIsLoading] = useState(false)
  const { balance } = useTokenBalance(publicKey, WORMHOLE_MINT)
  const [error, setError] = useState<Error | null>(null)
  const [transactionStatus, setTransactionStatus] = useState(
    TransactionStatus.NONE,
  )
  const [previousTransactionStatus, setPreviousTransactionStatus] = useState(
    TransactionStatus.NONE,
  )
  const { voteModal } = useModals()

  const getStakeMetadataAddress = useCallback(
    async (ownerAddress: PublicKey): Promise<PublicKey | undefined> => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        setIsLoading(true)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const [stakeMetadataAccount] = PublicKey.findProgramAddressSync(
          [Buffer.from(ConfigSeeds.STAKE_METADATA), ownerAddress.toBuffer()],
          program.programId,
        )

        const account =
          await program.account.stakeAccountMetadata.fetchNullable(
            stakeMetadataAccount,
          )

        return account !== null ? stakeMetadataAccount : undefined
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
      } finally {
        setIsLoading(false)
      }
    },
    [connection, wallet, signTransaction, signAllTransactions, publicKey],
  )

  const getStakeAccountCheckpointsAddressByMetadata = useCallback(
    async (
      stakeAccountAddress: PublicKey | undefined,
      previous: boolean,
    ): Promise<PublicKey | undefined> => {
      if (
        !wallet ||
        !signTransaction ||
        !signAllTransactions ||
        !publicKey ||
        !stakeAccountAddress
      ) {
        return
      }

      try {
        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const account = await program.account.stakeAccountMetadata.fetch(
          stakeAccountAddress,
        )

        const currentIndex = previous
          ? account?.stakeAccountCheckpointsLastIndex - 1
          : account?.stakeAccountCheckpointsLastIndex

        const [checkpointDataAccountPublicKey] =
          PublicKey.findProgramAddressSync(
            [
              Buffer.from(ConfigSeeds.CHECKPOINT_DATA),
              account?.owner.toBuffer(),
              Buffer.from([currentIndex, 0]),
            ],
            program.programId,
          )

        const accountData = await program.account.checkpointData.fetchNullable(
          checkpointDataAccountPublicKey,
        )

        return accountData !== null ? checkpointDataAccountPublicKey : undefined
      } catch {
        return
      }
    },
    [connection, wallet, signTransaction, signAllTransactions, publicKey],
  )

  const getStakeAccountCheckpointsAddress = useCallback(
    async (user: PublicKey, index: number) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const account = await program.account.stakeAccountMetadata.fetch(user)

        const [checkpointDataAccountPublicKey] =
          PublicKey.findProgramAddressSync(
            [
              Buffer.from(ConfigSeeds.CHECKPOINT_DATA),
              account?.owner.toBuffer(),
              Buffer.from([index]),
            ],
            program.programId,
          )

        const accountData = await program.account.checkpointData.fetchNullable(
          checkpointDataAccountPublicKey,
        )

        return accountData !== null ? checkpointDataAccountPublicKey : undefined
      } catch (err) {
        return
      }
    },
    [connection, publicKey, signAllTransactions, signTransaction, wallet],
  )

  const getStakeAccountCustody = useCallback(
    async (address: PublicKey) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const [checkpointAddress] = PublicKey.findProgramAddressSync(
          [Buffer.from(ConfigSeeds.CUSTODY), address.toBuffer()],
          program.programId,
        )

        return checkpointAddress
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
      }
    },
    [connection, wallet, signTransaction, signAllTransactions, publicKey],
  )

  const getStakeAccountMetadata = useCallback(
    async (address: PublicKey): Promise<StakeAccountMetadata | undefined> => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const [metadataAddress] = PublicKey.findProgramAddressSync(
          [Buffer.from(ConfigSeeds.STAKE_METADATA), address.toBuffer()],
          program.programId,
        )

        const accountData =
          await program.account.stakeAccountMetadata.fetchNullable(
            metadataAddress,
          )

        return accountData as StakeAccountMetadata
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
      }
    },
    [connection, wallet, signTransaction, signAllTransactions, publicKey],
  )

  const getProposalAccount = useCallback(
    async (proposalId: string) => {
      if (!signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        const proposalIdBN = new BN(proposalId)
        const proposalIdBuffer = proposalIdBN.toArrayLike(Buffer, "be", 32)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const [proposalAccount] = PublicKey.findProgramAddressSync(
          [Buffer.from(ConfigSeeds.PROPOSAL), proposalIdBuffer],
          program.programId,
        )

        const data = await program.account.proposalData.fetchNullable(
          proposalAccount,
        )

        return { proposalAccount, data }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
      }
    },
    [connection, signTransaction, publicKey, signAllTransactions],
  )

  const delegates = useCallback(
    async (stakeAccount: PublicKey): Promise<PublicKey | undefined> => {
      const stakeAccountMetadata = await getStakeAccountMetadata(stakeAccount)

      return stakeAccountMetadata?.delegate
    },
    [getStakeAccountMetadata],
  )

  const createStakeAccount = useCallback(async () => {
    if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
      return
    }

    try {
      setTransactionStatus(TransactionStatus.CREATE_ACCOUNT)
      setIsLoading(true)
      setError(null)

      const provider = new AnchorProvider(
        connection,
        { signTransaction, signAllTransactions, publicKey },
        AnchorProvider.defaultOptions(),
      )
      const program = new Program(IDL, provider) as unknown as Program<Staking>

      const tx = await program.methods
        .createStakeAccount()
        .accounts({ mint: WORMHOLE_MINT })
        .transaction()

      const signature = await sendTransaction(tx, connection)
      const latestBlockhash = await connection.getLatestBlockhash()
      await connection.confirmTransaction({
        signature,
        ...latestBlockhash,
      })

      setTransactionStatus(TransactionStatus.SUCCESS)

      setTimeout(() => {
        setTransactionStatus(TransactionStatus.NONE)
      }, 3000)

      return signature
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("An unknown error occurred"),
      )
      console.info(err)
      setTransactionStatus(TransactionStatus.FAIL)
    } finally {
      setIsLoading(false)
      setPreviousTransactionStatus(TransactionStatus.CREATE_ACCOUNT)
    }
  }, [
    publicKey,
    connection,
    wallet,
    signTransaction,
    signAllTransactions,
    sendTransaction,
  ])

  const withCreateStakeAccount = useCallback(
    async (
      instructions: TransactionInstruction[],
      owner: PublicKey,
    ): Promise<PublicKey> => {
      const provider = new AnchorProvider(
        connection,
        { signTransaction, signAllTransactions, publicKey } as Wallet,
        AnchorProvider.defaultOptions(),
      )
      const program = new Program(IDL, provider) as unknown as Program<Staking>

      const [checkpointDataAddress] = PublicKey.findProgramAddressSync(
        [
          Buffer.from(ConfigSeeds.CHECKPOINT_DATA),
          owner.toBuffer(),
          Buffer.from([0]),
        ],
        program.programId,
      )
      instructions.push(
        await program.methods
          .createStakeAccount()
          .accounts({
            mint: WORMHOLE_MINT,
          })
          .instruction(),
      )

      return checkpointDataAddress
    },
    [connection, signTransaction, signAllTransactions, publicKey],
  )

  // NOTE: unlike how it works in ERC20Votes, wormhole staking requires that you
  // select an amount of your balance (W token in this case) to transfer to your
  // stake account that can then be used for participating in governance
  const delegateTokenAmount = useCallback(
    async (
      stakeAccountCheckpointsAddress: PublicKey,
      amount: BN,
      programId: PublicKey,
    ) => {
      const fromAccount = await getAssociatedTokenAddress(
        WORMHOLE_MINT,
        publicKey as PublicKey,
        true,
      )

      const [toAccount] = PublicKey.findProgramAddressSync(
        [
          Buffer.from(ConfigSeeds.CUSTODY),
          stakeAccountCheckpointsAddress.toBuffer(),
        ],
        programId,
      )

      return createTransferInstruction(
        fromAccount,
        toAccount,
        publicKey as PublicKey,
        amount.toNumber(),
      )
    },
    [publicKey],
  )

  const transferToStakingAccount = useCallback(
    async (amount: BN) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        setTransactionStatus(TransactionStatus.TRANSFER_TO_STAKE_ACCOUNT)
        setIsLoading(true)
        setError(null)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const ix = await delegateTokenAmount(
          publicKey,
          amount,
          program.programId,
        )

        const latestBlockhash = await connection.getLatestBlockhash()
        const transaction = new VersionedTransaction(
          new TransactionMessage({
            payerKey: publicKey,
            recentBlockhash: latestBlockhash.blockhash,
            instructions: [ix],
          }).compileToV0Message(),
        )

        const signature = await sendTransaction(transaction, connection)

        setTransactionStatus(TransactionStatus.SUCCESS)

        setTimeout(() => {
          setTransactionStatus(TransactionStatus.NONE)
        }, 3000)

        return signature
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
        setTransactionStatus(TransactionStatus.FAIL)
      } finally {
        setIsLoading(false)
        setPreviousTransactionStatus(
          TransactionStatus.TRANSFER_TO_STAKE_ACCOUNT,
        )
      }
    },
    [
      delegateTokenAmount,
      publicKey,
      connection,
      sendTransaction,
      signAllTransactions,
      signTransaction,
      wallet,
    ],
  )

  const withdrawFromStakingAccount = useCallback(
    async (
      custodyBalance: WHTokenBalance,
      stakeAccount: PublicKey,
      amount: WHTokenBalance,
    ) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        setTransactionStatus(TransactionStatus.WITHDRAW)
        setIsLoading(true)
        setError(null)

        if (amount.toBN().gt(custodyBalance.toBN())) {
          throw new Error("Amount exceeds withdrawable.")
        }

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const toAccount = await getAssociatedTokenAddress(
          WORMHOLE_MINT,
          publicKey,
          true,
        )

        const instructions: TransactionInstruction[] = []

        const stakeAccountCheckpointsData =
          await program.account.stakeAccountMetadata.fetch(stakeAccount)

        const currentDelegateStakeAccountCheckpointsOwner = await delegates(
          stakeAccountCheckpointsData.owner,
        )

        if (!currentDelegateStakeAccountCheckpointsOwner) {
          return
        }

        const currentDelegateStakeAccountMetadataAddress =
          await getStakeMetadataAddress(
            currentDelegateStakeAccountCheckpointsOwner,
          )

        if (!currentDelegateStakeAccountMetadataAddress) {
          return
        }

        const currentDelegateStakeAccountCheckpointsAddress =
          await getStakeAccountCheckpointsAddressByMetadata(
            currentDelegateStakeAccountMetadataAddress,
            false,
          )

        if (!currentDelegateStakeAccountCheckpointsAddress) {
          return
        }

        instructions.push(
          await program.methods
            .withdrawTokens(
              amount.toBN(),
              currentDelegateStakeAccountCheckpointsOwner,
              stakeAccountCheckpointsData.owner,
            )
            .accountsPartial({
              currentDelegateStakeAccountCheckpoints:
                currentDelegateStakeAccountCheckpointsAddress,
              destination: toAccount,
            })
            .instruction(),
        )

        const latestBlockhash = await connection.getLatestBlockhash()
        const transaction = new VersionedTransaction(
          new TransactionMessage({
            payerKey: publicKey,
            recentBlockhash: latestBlockhash.blockhash,
            instructions,
          }).compileToV0Message(),
        )

        const signature = await sendTransaction(transaction, connection)

        setTransactionStatus(TransactionStatus.SUCCESS)

        setTimeout(() => {
          setTransactionStatus(TransactionStatus.NONE)
        }, 3000)

        return signature
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
        setTransactionStatus(TransactionStatus.FAIL)
      } finally {
        setIsLoading(false)
        setPreviousTransactionStatus(TransactionStatus.WITHDRAW)
      }
    },
    [
      connection,
      delegates,
      getStakeAccountCheckpointsAddressByMetadata,
      getStakeMetadataAddress,
      publicKey,
      sendTransaction,
      signTransaction,
      signAllTransactions,
      wallet,
    ],
  )

  const createStakeAccountAndTransfer = useCallback(
    async (amount: string) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      const instructions: TransactionInstruction[] = []

      try {
        setTransactionStatus(TransactionStatus.CREATE_AND_TRANSFER)
        setIsLoading(true)
        setError(null)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const createStakeAccountIx = await program.methods
          .createStakeAccount()
          .accounts({ mint: WORMHOLE_MINT })
          .instruction()

        instructions.push(createStakeAccountIx)

        if (new BN(balance).gt(new BN(0))) {
          instructions.push(
            await delegateTokenAmount(
              publicKey,
              WHTokenBalance.fromString(amount).toBN(),
              program.programId,
            ),
          )
        }

        const latestBlockhash = await connection.getLatestBlockhash()
        const transaction = new VersionedTransaction(
          new TransactionMessage({
            payerKey: publicKey,
            recentBlockhash: latestBlockhash.blockhash,
            instructions,
          }).compileToV0Message(),
        )

        const signature = await sendTransaction(transaction, connection)

        setTransactionStatus(TransactionStatus.SUCCESS)

        setTimeout(() => {
          setTransactionStatus(TransactionStatus.NONE)
        }, 3000)

        return signature
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
        setTransactionStatus(TransactionStatus.FAIL)
      } finally {
        setIsLoading(false)
        setPreviousTransactionStatus(TransactionStatus.CREATE_AND_TRANSFER)
      }
    },
    [
      balance,
      connection,
      delegateTokenAmount,
      publicKey,
      signAllTransactions,
      signTransaction,
      wallet,
      sendTransaction,
    ],
  )

  const castVote = useCallback(
    async (
      proposalId: string,
      againstVotes: BN,
      forVotes: BN,
      abstainVotes: BN,
    ) => {
      if (!wallet || !signTransaction || !signAllTransactions || !publicKey) {
        return
      }

      try {
        setTransactionStatus(TransactionStatus.CAST_VOTE)
        setIsLoading(true)
        setError(null)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const stakeAccountMetadata = await getStakeAccountMetadata(publicKey)

        if (!stakeAccountMetadata) {
          throw new Error("No stake account metadata found")
        }

        const currentCheckpointIndex =
          stakeAccountMetadata.stakeAccountCheckpointsLastIndex
        const votingCheckpointIndex = Math.max(0, currentCheckpointIndex - 1)

        const voterStakeAccountCheckpointsAddress =
          await getStakeAccountCheckpointsAddress(
            publicKey,
            votingCheckpointIndex,
          )

        const nextVoterStakeAccountCheckpointsAddress =
          await getStakeAccountCheckpointsAddress(
            publicKey,
            votingCheckpointIndex + 1,
          )

        const instructions: TransactionInstruction[] = []

        const pa = await getProposalAccount(proposalId)

        if (!pa) {
          return
        }

        const proposalIdBN = new BN(proposalId)
        const proposalIdBuffer = proposalIdBN.toArrayLike(Buffer, "be", 32)

        instructions.push(
          await program.methods
            .castVote(
              Array.from(proposalIdBuffer),
              againstVotes,
              forVotes,
              abstainVotes,
              votingCheckpointIndex,
            )
            .accountsPartial({
              proposal: pa.proposalAccount,
              voterCheckpoints: voterStakeAccountCheckpointsAddress,
              voterCheckpointsNext:
                nextVoterStakeAccountCheckpointsAddress === undefined
                  ? null
                  : nextVoterStakeAccountCheckpointsAddress,
            })
            .instruction(),
        )

        const latestBlockhash = await connection.getLatestBlockhash()
        const transaction = new VersionedTransaction(
          new TransactionMessage({
            payerKey: publicKey,
            recentBlockhash: latestBlockhash.blockhash,
            instructions,
          }).compileToV0Message(),
        )

        const signature = await sendTransaction(transaction, connection)
        voteModal.onClose()

        setTransactionStatus(TransactionStatus.SUCCESS)

        setTimeout(() => {
          setTransactionStatus(TransactionStatus.NONE)
        }, 3000)

        return signature
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
        setTransactionStatus(TransactionStatus.FAIL)
      } finally {
        setIsLoading(false)
        setPreviousTransactionStatus(TransactionStatus.CAST_VOTE)
      }
    },
    [
      wallet,
      signTransaction,
      signAllTransactions,
      publicKey,
      voteModal,
      connection,
      getStakeAccountMetadata,
      getStakeAccountCheckpointsAddress,
      getProposalAccount,
      sendTransaction,
    ],
  )

  const delegate = useCallback(
    async (
      delegatee: PublicKey,
      amount?: WHTokenBalance,
      includeVest = false,
      vestingConfigAccount: PublicKey | null = null,
    ) => {
      if (
        !wallet ||
        !signTransaction ||
        !signAllTransactions ||
        !publicKey ||
        !delegatee
      ) {
        return
      }

      try {
        setTransactionStatus(TransactionStatus.DELEGATE)
        setIsLoading(true)
        setError(null)

        const provider = new AnchorProvider(
          connection,
          { signTransaction, signAllTransactions, publicKey },
          AnchorProvider.defaultOptions(),
        )
        const program = new Program(
          IDL,
          provider,
        ) as unknown as Program<Staking>

        const instructions: TransactionInstruction[] = []

        const stakeAccountMetadataAddress = await getStakeMetadataAddress(
          publicKey,
        )

        let stakeAccountCheckpointsAddress =
          await getStakeAccountCheckpointsAddressByMetadata(
            stakeAccountMetadataAddress,
            false,
          )

        let currentDelegateStakeAccountCheckpointsAddress: PublicKey
        let currentDelegateStakeAccountOwner: PublicKey | undefined
        let delegateeStakeAccountOwner = await getStakeMetadataAddress(
          delegatee,
        )

        if (!delegateeStakeAccountOwner) {
          return
        }

        let vestingBalanceAccount: PublicKey | null = null

        if (!stakeAccountCheckpointsAddress) {
          stakeAccountCheckpointsAddress = await withCreateStakeAccount(
            instructions,
            publicKey,
          )

          currentDelegateStakeAccountCheckpointsAddress =
            stakeAccountCheckpointsAddress
          currentDelegateStakeAccountOwner = publicKey
        } else {
          currentDelegateStakeAccountOwner = await delegates(publicKey)

          console.info(
            "current delegate",
            currentDelegateStakeAccountOwner?.toBase58(),
          )

          if (!currentDelegateStakeAccountOwner) {
            currentDelegateStakeAccountOwner = publicKey
          }

          const currentDelegateStakeAccountMetadataAddress =
            await getStakeMetadataAddress(currentDelegateStakeAccountOwner)

          const delegateStakeAccountAddress =
            await getStakeAccountCheckpointsAddressByMetadata(
              currentDelegateStakeAccountMetadataAddress,
              false,
            )

          if (!delegateStakeAccountAddress) {
            throw new Error(
              "unable to get stake account address for the current delegate",
            )
          }

          currentDelegateStakeAccountCheckpointsAddress =
            delegateStakeAccountAddress
        }

        let delegateeStakeAccountCheckpointsAddress: PublicKey | undefined

        if (delegatee) {
          const delegateeStakeAccountMetadata = await getStakeMetadataAddress(
            delegatee,
          )

          console.info(
            "i want to delegate to",
            delegatee.toBase58(),
            delegateeStakeAccountMetadata?.toBase58(),
          )

          delegateeStakeAccountCheckpointsAddress =
            await getStakeAccountCheckpointsAddressByMetadata(
              delegateeStakeAccountMetadata,
              false,
            )
          delegateeStakeAccountOwner = delegatee
        }

        if (!delegateeStakeAccountCheckpointsAddress) {
          delegateeStakeAccountCheckpointsAddress =
            currentDelegateStakeAccountCheckpointsAddress
          delegateeStakeAccountOwner = currentDelegateStakeAccountOwner
        }

        if (includeVest && vestingConfigAccount) {
          const [vba] = PublicKey.findProgramAddressSync(
            [
              Buffer.from(ConfigSeeds.VESTING_BALANCE),
              vestingConfigAccount.toBuffer(),
              publicKey.toBuffer(),
            ],
            program.programId,
          )
          vestingBalanceAccount = vba
        }

        if (amount?.toBN().gt(new BN(0))) {
          instructions.push(
            await delegateTokenAmount(
              publicKey,
              amount.toBN(),
              program.programId,
            ),
          )
        }

        instructions.push(
          await program.methods
            .delegate(
              delegateeStakeAccountOwner,
              currentDelegateStakeAccountOwner,
            )
            .accountsPartial({
              currentDelegateStakeAccountCheckpoints:
                currentDelegateStakeAccountCheckpointsAddress,
              delegateeStakeAccountCheckpoints:
                delegateeStakeAccountCheckpointsAddress,
              vestingConfig: vestingConfigAccount,
              vestingBalance: vestingBalanceAccount,
              mint: WORMHOLE_MINT,
            })
            .instruction(),
        )

        const latestBlockhash = await connection.getLatestBlockhash()
        const transaction = new VersionedTransaction(
          new TransactionMessage({
            payerKey: publicKey,
            recentBlockhash: latestBlockhash.blockhash,
            instructions,
          }).compileToV0Message(),
        )

        const signature = await sendTransaction(transaction, connection)

        setTransactionStatus(TransactionStatus.SUCCESS)

        setTimeout(() => {
          setTransactionStatus(TransactionStatus.NONE)
        }, 3000)

        return signature
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred"),
        )
        setTransactionStatus(TransactionStatus.FAIL)
      } finally {
        setIsLoading(false)
        setPreviousTransactionStatus(TransactionStatus.DELEGATE)
      }
    },
    [
      publicKey,
      connection,
      sendTransaction,
      getStakeMetadataAddress,
      getStakeAccountCheckpointsAddressByMetadata,
      delegates,
      signAllTransactions,
      signTransaction,
      wallet,
      withCreateStakeAccount,
      delegateTokenAmount,
    ],
  )

  return {
    castVote,
    delegate,
    delegates,
    createStakeAccount,
    createStakeAccountAndTransfer,
    getStakeMetadataAddress,
    getStakeAccountCustody,
    withdrawFromStakingAccount,
    transferToStakingAccount,
    transactionStatus,
    previousTransactionStatus,
    getProposalAccount,
    isLoading,
    error,
  }
}
